body {
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
}

a {
  color: #00B7FF;
}

.hide {
  display: none!important;
}

.inline-block {
  display: inline-block
}

@media print {
  .no-print {
    display: none;
  }
}

.downloadLink{
  color: #00B7FF;
  cursor: pointer;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}
