// Main app header
#header{
  display: grid;
  grid-template-columns: 200px 4fr 250px;
  column-gap: 12px;

  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 12px;
  padding: 12px;

  .gridArea{
    grid-area: auto;
    align-self: center; // Vertical align
    // grid-column: span 1;
  }

  #brandName{
    display: inline-block;

    h1{
      display: inline-block;
      margin: 0;
    }
  }

  ul{
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
    li{
      display: inline-block;
      margin-right: 12px;
    }
  }

  #signin{
    justify-self: end; // Align to the right
  }
}

// Main app container
#content{
  padding: 12px;
  margin-bottom: 50px; // Allowing to have a fixed footer
}


// Highlight elements
div#recordHighlights{
  position: fixed;
  bottom: 10px;
  left: 50px;
  right: 50px;
  border-radius: 3px;
  z-index: 9;
  background-color: white;
  box-shadow: 0 0 20px rgba(0,0,0,0.7);
  padding: 6px;

  a{
    display: block;
    padding: 3px;
    margin: 3px;
  }
}
