.formRecords {
  width: 5000px; /* There should be another way to have enough width; forcing it for now */

  .cell {
    &.wide{
      width: 200px;
    }
  }
}

.feedColumns {
  width: 40px;
  text-align: right;
}
.columnTotals{
  width: 60px;
}
