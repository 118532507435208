.cssGrid {
  display: grid;

  div{
    padding: 3px;
    align-self: flex-start;

    p {
      padding: 3px 0;
      margin: 3px 0;
    }
  }

  .headerCell {
    border-bottom: 1px solid rgba(0,0,0,0.4);
    border-right: 1px solid rgba(0,0,0,0.4);
    font-weight: bold;
    align-self: end;
  }

  .bordered {
    border-top: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
  }
}
