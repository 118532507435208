.tableLayout {
  display: block;
  
  .row {
    display: block;
    margin-top: 3px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }

  .cell {
    display: inline-block;
    padding: 3px;
    overflow: hidden;
    vertical-align: top;

    &.header {
      font-weight: bold;
      vertical-align: bottom;

    }

    &.wide {
      width: 100px
    }
    &.small {
      width: 50px;
    }

    &.w60 {
      width: 60px;
    }
    
    &.w75 {
      width: 75px;
    }

    &.bordered {
      border-right: 1px solid rgba(0,0,0,0.2);
    }

  }

  .cellContentDiv {
    display: block;
    padding: 3px 0;
  }
}
