/* Form analysis */
table {
  border: 1px solid rgba(0,0,0,0.2);
}

table tr {
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

table td {
  border-right: 1px solid rgba(0,0,0,0.2);
  padding: 3px;
}
