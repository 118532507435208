body {
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
}
a {
  color: #00B7FF;
}
.hide {
  display: none!important;
}
.inline-block {
  display: inline-block;
}
@media print {
  .no-print {
    display: none;
  }
}
.downloadLink {
  color: #00B7FF;
  cursor: pointer;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.clickable {
  cursor: pointer;
}
#header {
  display: grid;
  grid-template-columns: 200px 4fr 250px;
  column-gap: 12px;
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 12px;
  padding: 12px;
}
#header .gridArea {
  grid-area: auto;
  align-self: center;
}
#header #brandName {
  display: inline-block;
}
#header #brandName h1 {
  display: inline-block;
  margin: 0;
}
#header ul {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}
#header ul li {
  display: inline-block;
  margin-right: 12px;
}
#header #signin {
  justify-self: end;
}
#content {
  padding: 12px;
  margin-bottom: 50px;
}
div#recordHighlights {
  position: fixed;
  bottom: 10px;
  left: 50px;
  right: 50px;
  border-radius: 3px;
  z-index: 9;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  padding: 6px;
}
div#recordHighlights a {
  display: block;
  padding: 3px;
  margin: 3px;
}
@media print {
  .chart {
    width: 47vw;
    height: 18vh;
    border: 2px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    border-radius: 3px;
  }
  .chart canvas {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto!important;
    width: auto!important;
    position: relative;
  }
}
@media screen {
  .chart {
    width: 47vw;
    /* 49%; /* 600px; */
    height: 30vh;
    /* 400px; */
    min-height: 400px;
    /* Avoid vertical small chart */
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    border-radius: 3px;
  }
}
div.fullScreen {
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  background-color: white;
  z-index: 9;
  /* on top */
  box-shadow: 0px 0px 300px #000000;
}
div.fullScreen.chart {
  width: auto;
  height: auto;
}
div.chart .fullScreenToggle {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* font-size: 0.4em;
    color: rgba(0,0,0,0.5) */
}
div.chart .downloadButton {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  position: absolute;
  background-color: rgba(150, 40, 40, 0.1);
  top: 10px;
  right: 30px;
  cursor: pointer;
}
div.layoutFilterTag {
  background-color: rgba(150, 150, 50, 0.3);
  padding: 3px 6px;
  margin: 3px;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
}
div.layoutFilterTag.disabled {
  background-color: rgba(150, 150, 50, 0.1);
}
/* Form analysis */
table {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
table tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
table td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px;
}
.tableLayout {
  display: block;
}
.tableLayout .row {
  display: block;
  margin-top: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.tableLayout .cell {
  display: inline-block;
  padding: 3px;
  overflow: hidden;
  vertical-align: top;
}
.tableLayout .cell.header {
  font-weight: bold;
  vertical-align: bottom;
}
.tableLayout .cell.wide {
  width: 100px;
}
.tableLayout .cell.small {
  width: 50px;
}
.tableLayout .cell.w60 {
  width: 60px;
}
.tableLayout .cell.w75 {
  width: 75px;
}
.tableLayout .cell.bordered {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.tableLayout .cellContentDiv {
  display: block;
  padding: 3px 0;
}
.cssGrid {
  display: grid;
}
.cssGrid div {
  padding: 3px;
  align-self: flex-start;
}
.cssGrid div p {
  padding: 3px 0;
  margin: 3px 0;
}
.cssGrid .headerCell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  font-weight: bold;
  align-self: end;
}
.cssGrid .bordered {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.feedTable {
  width: 2500px;
  /* There should be another way to have enough width; forcing it for now */
}
.feedTable .row.daySeparator {
  height: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 0;
}
.feedTable .cell.header.ingredient {
  transform: rotate(-45deg);
  transform-origin: top left;
  overflow: visible;
}
.feedTable .cell.ingredient {
  width: 40px;
}
.feedTable .cell.left:nth-child(1) {
  width: 160px;
}
.feedTable .cell.left:nth-child(2) {
  width: 60px;
}
.feedTable .cell.left:nth-child(3) {
  width: 60px;
}
.feedTable .cell.left:nth-child(4) {
  width: 80px;
}
.feedTable .cell.left:nth-child(5) {
  width: 80px;
}
.feedTable .cell.left:nth-child(6) {
  width: 240px;
}
.feedTable .cell.left:nth-child(7) {
  width: 80px;
}
.feedTable .cell.isSetpoint {
  color: rgba(0, 0, 0, 0.5);
}
.feedColumns {
  width: 40px;
  text-align: right;
}
.columnTotals {
  width: 60px;
}
.formRecords {
  width: 5000px;
  /* There should be another way to have enough width; forcing it for now */
}
.formRecords .cell.wide {
  width: 200px;
}
.feedColumns {
  width: 40px;
  text-align: right;
}
.columnTotals {
  width: 60px;
}
