.feedTable {
  width: 2500px; /* There should be another way to have enough width; forcing it for now */

  .row {
    
    &.daySeparator{
      height: 10px;
      width: 100%;
      background-color: rgba(0,0,0,0.1);
      margin-top: 0;
    }
  }

  .cell {
    &.header {
      &.ingredient {
        transform: rotate(-45deg);
        transform-origin: top left;
        overflow: visible;
      }
    }
    &.ingredient {
      width: 40px;
    }

    &.left:nth-child(1) {
      width: 160px;
    }
    &.left:nth-child(2) {
      width: 60px;
    }
    &.left:nth-child(3) {
      width: 60px;
    }
    &.left:nth-child(4) {
      width: 80px;
    }
    &.left:nth-child(5) {
      width: 80px;
    }
    &.left:nth-child(6) {
      width: 240px;
    }
    &.left:nth-child(7) {
      width: 80px;
    }

    &.isSetpoint {
      color: rgba(0,0,0,0.5)
    }
  }
}

.feedColumns {
  width: 40px;
  text-align: right;
}
.columnTotals{
  width: 60px;
}
