
@media print {
  .chart {
    width: 47vw;
    height: 18vh;
    border: 2px solid rgba(0,0,0,0.2);
    display: inline-block;
    position: relative;
    border-radius: 3px;

    canvas {
      min-height: 100%;
      max-height: 100%;
      min-width: 100%;
      max-width: 100%;
      height: auto!important;
      width: auto!important;
      position: relative;
    }
  }
}

@media screen {
  .chart {
    width: 47vw; /* 49%; /* 600px; */
    height: 30vh; /* 400px; */
    min-height: 400px; /* Avoid vertical small chart */
    border: 1px solid rgba(0,0,0,0.2);
    display: inline-block;
    position: relative;
    border-radius: 3px;
  }
}

div.fullScreen {
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  background-color: white;
  z-index: 9; /* on top */
  box-shadow: 0px 0px 300px rgba(0,0,0,1);
  
  &.chart {
    width: auto;
    height: auto;
  }
}

div.chart {
  .fullScreenToggle {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    position: absolute;
    background-color: rgba(0,0,0,0.1);
    top: 10px;
    right: 10px;
    cursor: pointer;
    /* font-size: 0.4em;
    color: rgba(0,0,0,0.5) */
  }
  
  .downloadButton {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    position: absolute;
    background-color: rgba(150,40,40,0.1);
    top: 10px;
    right: 30px;
    cursor: pointer;
  }
}

div.layoutFilterTag {
  background-color: rgba(150,150,50,0.3);
  padding: 3px 6px;
  margin: 3px;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
  
  &.disabled {
    background-color: rgba(150,150,50,0.1);
  }
}
